import Terms_and_conditions from './terms/Terms_and_conditions_page'
import Privacy_policy from './privacy_page/Privacy_page'
import Contact_page from './contact_page/Contact_page'
import Home_page from './home_page/Home_page'
import Profile_page from './profile_page/Profile_page'
import PageNotFound from './404_page/404_page'
////////////////
import { BrowserRouter, Routes, Route } from "react-router-dom";
////////////////

export default function App() {
  ////////////////
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/terms_and_conditions" element={<Terms_and_conditions />} />
          <Route path="/privacy_policy" element={<Privacy_policy />} />
          <Route path="/contact_page" element={<Contact_page />} />
          <Route path="/profile_page" element={<Profile_page />} />
          <Route path="/" element={<Home_page />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}
