import React from 'react'
import './Privacy_page.css'
import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="Privacy_page">

        <div className="Privacy_page_main_div">

          <p>

            <b> Privacy Policy:</b>  Date-This-Guy respects the privacy of its users.
            <br />Please review our Privacy Policy to understand how we collect, use, and disclose information.
            <br />We do not collect Technical information about the device and the installed OS.
            <br />Functional features of the app are free to use.
            <br />The copyright holder of the application is Bulgarian, registered in Varna Bulgaria.
            <br />The chosen legal system and legislation that will be applied in resolving disputes and regulating legal relations are the EU laws.
            <br />
            <br />

            Last updated: 1.1.2025
            <br /> <br />
            Thank you for being a part of Date-This-Guy.
            <br /> <br /> Happy dating!
          </p>
          <Link to='/' ><button>Go back to the start</button></Link>

        </div>

      {/* Copyright */}
      <br />
      <br />
      <footer>
        <div>
          © 2021 Copyright:{" "}
          <a
            href="https://rocnogu.com"
            rel="noreferrer noopener"
            target="_blank"
            style={{ color: "red" }}
          >
            rocnogu and SOFU Projects

          </a>
        </div>
      </footer>
    </div>
  )
}
