import './Contact_page.css';
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import { Link } from "react-router-dom";
/////////////
export default function Contact_page() {

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: '‘error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };
  ///////////
  return (
    <div className="Contact_page">
      <div className="Contact_page_main_div">
      <h3>This form send email to us (SOFU), not to the person diretly. So, don't be nasty</h3>

        <Form onSubmit={handleOnSubmit} id='contact_form'>
          <Form.Field
            id='form-email'
            control={Input}
            label='Email'
            name='user_email'
            placeholder='Email…'
            required
          />
          <Form.Field
            id='form-name'
            control={Input}
            label='Name'
            name='user_name'
            placeholder='Name…'
            required
          />
          <Form.Field
            id='form-textarea'
            control={TextArea}
            name='user_message'
            placeholder='Leave a Message if you want to.'
          />
          <Button 
          type='submit' 
          color='green' 
          id='button-control-opinion'>Submit</Button>
        </Form>
      </div>
      <p>In case the form doesn't work you can send us email at:  <a href="mailto:sofu.projects@gmail.com" target="_blank" rel="noopener noreferrer">sofu.projects@gmail.com</a></p>
      <div id='contact_back_link'><Link to='/'  ><button>Go back to the start</button></Link></div>

 {/* Copyright */}
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
    </div>
  );
}



