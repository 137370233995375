import './Profile_page.css';
import { Link } from "react-router-dom";

export default function Profile_page() {
  return (
      <div className="Profile_page_main_div">
        <h3>Quidquid latine dictum sit, altum sonatur
        </h3>
        <p>
          REMEMBER: You have no obligations here.
          <br />
          Life is short. Make sure you spend as much time as you can, arguing with a complete stranger on the internet about things you don't agree with and don’t care about.
        </p>
        <h6>****</h6>
        <h1 id='profile_page_h1'>Meet Pavel </h1>
        <h3 id='profile_page_h3'>42 years old, from Bulgaria, with traditional Bulgarian values</h3>
        <div>
          <img src="img/Pavel-1.jpg" alt="pavel_img" />
        </div>

       
       

        <h3>ABOUT ME</h3>
        <p>First, I want to point out the most important thing – I am... somewhat cursed with intelligence.
        Totally different levels of perception and communication.
          <br />
          Why I say this is that I don't fit in any of your boxes with human types. Pretty sure you never met anyone like me before.
          <br />
        So. Forget everything you know or think you know about men and people. That’s not me. Open a new blank page and free your mind.
          <br />*<br />
          I’m DTF – Down To Finally be in a relationship with someone who cares about us, me, our health and we support each other.
          <br />*<br />
          I took some selfies for this profile and then I saw them, I realized that personality is more important, but not several.
          <br />*<br />
          I don’t smoke, drink, cheat, abuse, gamble or use drugs. I surf this life sober.  I lead a modest, calm lifestyle.
          <br />Curiosity is one of my main characteristics, a social happy leader. Equity, integrity and fairness are big things for me.
          <br />Strong mentally and physically, not greedy or possessive, like intimacy, cuddling and touch. Very practical Minimalist, down to earth, realist, genuine, friendly, good person, authentic, relaxed, honest, trustworthy, reliable, sarcastic, wholesome, peaceful, slow to anger, fast to laugh, home person, extroverted introvert. A decent, no-baggage guy. The type of guy you bring home, show to your friends and family, snug on the couch, eat pizza and watch a movie.
          <br />Being myself without pretending or doing things only so people could like me, don’t care what people think, but do care for my friends, family, and environment.
          <br />Family and friends are most important to me. I’ve learned that money come and goes, but friends and family can’t be replaced.
          <br />Grateful for the things I have in life. Open-minded (doesn't mean agreeable). Peaceful (doesn't mean harmless).
          <br /><br />
          I hope one day I can pass down those qualities to a family of my own. I have goodness in me and I look for that woman to share with, but I’ve been disappointed in the past and now I’m careful, I don’t take people for good by default. They have to prove themselves.
          <br /><br />
          <br />I’ve had to overcome hard life and that made me a stronger person.
          <br />My life also taught me to have inner strength and independence. I’m not afraid to be alone, but I don’t want to be alone.
          <br /><br />
          Hobbies:  Memes, cycling, video games, reading, psychology, philosophy, science, technology, DIY, comedies, anime.
          <br />And as every human – Music, movies, traveling, parks, nature, seeking like-minded.
          <br /><br />
          I’m also a handyman, I have knowledge about buildings, construction, mechanics and electronics. Can’t fix everything, but I’ll try.
          <br />A dream of mine is to build my own house someday, but also to try van life as well.
          Cancer – Ambivert – Quirkyalone – Chaotic Good – above average IQ – INTP/ESTJ/ENTJ – if these things matter to you.</p>
        <h6>****</h6>

        <h3>WHAT I LOOK FOR</h3>
        <p>Looking for a woman who likes to cycle ( I don’t own a car).
          <br />*<br />
          Wanting and ready for a family, honest, kind warm-hearted and open-minded, supportive, feminine, decent, taking responsibility and accountability, down to earth,  investing in the relationship as much as I do, a real partner in life with at least some sense of humor,
          <br /> She must let me steal. I love stealing…. hugs and butt grabs.
          <br />Wanting a meaningful relationship in which we grow old and fat together, a woman to work with me for a better future for us.
          <br /><br />
          <br />I have accepted my life and I am comfortable with it, to win me over your presence has to fit in, to feel good. To vibe on a spiritual, physical and intellectual level. Someone who inspires me to be better.
          <br />I’d like to find that beautiful type of relationship where life can be shared on a deeper level.
          <br />Ideally, looking for that woman to fit in my life completely, make my existence whole and make me happy taken.
          <br />But hey, no pressure.
     </p>
        <h6>****</h6>

        <h3>DEAL BREAKERS</h3>
        <p>Unhealthy(Body and mind), Toxic, Selfish, Narcissistic, Woke, Religious, Drama queen, Pretentious, Royal, Promiscuous, Sex worker, Gold digger, ”The Perfect”, "Strong independent", Feminist  and LGBT+++
     </p>

        <h6>****</h6>
        <h3>Photos</h3>

        <img src="img/Pavel-2.jpg" alt="pavel_img2" />
        <img src="img/Pavel-3.jpg" alt="pavel_img3" />
        <img src="img/Pavel-4.jpg" alt="pavel_img4" />
        <img src="img/Pavel-5.jpg" alt="pavel_img5" />
        <img src="img/Pavel-6.jpg" alt="pavel_img6" />

        <h6>****</h6>
        <h5>If you survived all of this and you want to talk to the man, click the button below. </h5>
        <Link to='/contact_page' ><button>Contact form</button></Link>
        <p>

<br />
<br />

Last updated: 1.1.2025
<br /> <br />
Thank you for being a part of Date-This-Guy.
<br /> <br /> Happy dating!
</p>
<Link to='/' ><button>Go back to the start</button></Link>



 {/* Copyright */}
 <br/>
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
      </div>
      
  );
}


