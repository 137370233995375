import './Home_page.css';
import { Link } from "react-router-dom";

export default function Home_page() {
  return (
    <div className="Home_page">
        <div className="Home_page_main_div">
          <h1>Join our global dating vision !</h1>
          <h3>Date-this-guy is an online dating app that matches you on what really matters, one guy at a time.</h3>
          <p>
            Tired of endless swiping? Date-this-guy, takes a different approach to all of this.
            <br />
            We believe, that everyone deserves to find their spouse and we hope that we can provide it.
          </p>
          <h4>Discover what it is all about.</h4>

          <Link to='/profile_page' ><button id="go_ahead">Go ahead</button></Link>
          <p>By clicking the Go ahead button, you agree to our <Link to='/terms_and_conditions' ><b>Terms and conditions</b></Link>.

            <br />
            To help keep internet safe, you agree and consent to the message safe and dating safe agendas.</p>

          <p> <Link to='/privacy_policy' ><b>Privacy Policy</b></Link>.</p>

        </div>

      {/* Copyright */}
      <br />
      <footer>
        <div>
          © 2021 Copyright:{" "}
          <a
            href="https://rocnogu.com"
            rel="noreferrer noopener"
            target="_blank"
            style={{ color: "red" }}
          >
            rocnogu and SOFU Projects
          </a>
        </div>
      </footer>
    </div>
  );
}

