import './404_page.css';
import { Link } from "react-router-dom";

export default function PageNotFound() {
  ////////////////
  return (
    <div className="PageNotFound">
      <div className="PageNotFound_main_div">
        <h1>Error 404</h1>
        <h1>
          There is no such page.
        </h1>

      </div>

 <br/>
        
        <div><Link to='/'  ><button>Go back to the start</button></Link></div>

 {/* Copyright */}
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
    </div>
  );
}





